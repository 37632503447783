import React, { FC, useState } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import { Navigation } from '../components/Navigation/Navigation'
import { ApiOptions, useApiOptions } from '../providers/ApiOptionsProvider'
import { useLogin } from '../providers/LoginProvider'
import { DatePicker } from '@mui/x-date-pickers'
import * as dateFns from 'date-fns'

const Header = () => {
  return (
    <Typography component="h1" variant="h2" sx={{ fontSize: '22px' }}>
      DSA Reports
    </Typography>
  )
}

// https://stackoverflow.com/a/59940621
// https://www.stefanjudis.com/snippets/how-trigger-file-downloads-with-javascript/
async function downloadDsaReport(
  { appId, appKey, commentsApiUrl }: ApiOptions,
  token: string,
  startDate: Date,
  endDate: Date
) {
  const endpointUrl = new URL('/v1/dsa/report', commentsApiUrl)
  endpointUrl.searchParams.set('app_id', appId)
  endpointUrl.searchParams.set('app_key', appKey)
  endpointUrl.searchParams.set(
    'start-date',
    dateFns.formatISO(startDate, { representation: 'date' })
  )
  endpointUrl.searchParams.set('end-date', dateFns.formatISO(endDate, { representation: 'date' }))

  const response = await fetch(endpointUrl, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  const contentDisposition = response.headers.get('content-disposition')!
  const filename = contentDisposition.match(/filename="(.*)"/)![1]

  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = URL.createObjectURL(await response.blob())
  link.download = filename

  document.body.appendChild(link)
  link.click()

  setTimeout(() => {
    URL.revokeObjectURL(link.href)
    link.remove()
  })
}

export const DsaReportsPage: FC = () => {
  const apiOptions = useApiOptions()
  const { token } = useLogin()

  const [startDate, setStartDate] = useState<Date>(dateFns.parseISO('2024-02-17'))
  const [endDate, setEndDate] = useState<Date>(dateFns.parseISO('2025-02-16'))

  return (
    <Navigation currentRoute="dsaReports">
      <Paper
        sx={{
          px: 3,
          py: 3,
          minWidth: 'min(600px, 100%)',
          maxWidth: 600,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: 3,
          border: '1px solid #79747E',
          borderRadius: '16px',
          boxShadow: 'none',
          overflow: 'hidden'
        }}
      >
        <Header />
        <Box>
          <p>Here you can download the data needed for the DSA transparency report.</p>
          <p>
            Note that the returned file is <strong>not the actual report!</strong> You need to copy
            and paste its data into the correct locations in the official transparency report
            template.
          </p>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            maxWidth: 'max-content'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2
            }}
          >
            <DatePicker
              label="Report start date (inclusive)"
              value={startDate}
              onChange={value => {
                if (value) {
                  setStartDate(value)
                }
              }}
            />
            <DatePicker
              label="Report end date (inclusive)"
              value={endDate}
              onChange={value => {
                if (value) {
                  setEndDate(value)
                }
              }}
            />
          </Box>
          <Button
            onClick={() => downloadDsaReport(apiOptions, token!, startDate, endDate)}
            sx={{
              fontSize: '14px',
              maxWidth: 'max-content',
              alignSelf: 'flex-end'
            }}
            variant="contained"
          >
            Download data
          </Button>
        </Box>
      </Paper>
    </Navigation>
  )
}
