/* istanbul ignore file */

export const routes = {
  moderation: '/moderation',
  adminUsers: '/users',
  bannedUsers: '/bannedUsers',
  dsaReports: '/dsaReports'
}

export type Route = keyof typeof routes
